import React from "react"

import { StaticImage } from "gatsby-plugin-image"

const div = ({}) => {
  return (
    <div className="bg-white">
      <h2 className="text-center pt-24  text-4xl text-black font-semibold tracking-wider">
        Access anywhere
      </h2>

      <div className="py-24 container px-3 mx-auto flex flex-wrap justify-center">
        <div className="w-full lg:w-1/2 py-4 flex pr-16">
          <StaticImage
            src="../images/load-instructions-on-a-tablet.png"
            alt="Responsive application which means you can use it on your phone or tablet"
          />
        </div>
        <div className="w-full lg:w-1/2 p-4 flex items-center">
          <p className="leading-normal text-xl leading-normal">
            The Cargo-Planner application is responsive which means you can open
            it up in your phone or tablet as well. Use it on the warehouse floor
            to get step by step loading instructions - having the load plans
            synced with the control tower.
          </p>
        </div>
      </div>
    </div>
  )
}

export default div

import React from "react"

import Testimonial from "./testimonial"
import { useStaticQuery, graphql } from "gatsby" // to query for image data

const T = ({}) => {
  return (
    <Testimonial
      by="Hauke Weidemann, Commercial Manager, F.H. Bertling Logistics GmbH"
      text="During our daily work on executing various projects, we often need
      to calculate the required equipment for our cargoes from containers
      to trucks to mafi trailers. By using Cargoplanner we are able to
      save a lot of time during this part of our work."
    ></Testimonial>
  )
}

export default T

import React from "react"

const Capterra = ({}) => {
  return (
    <div className="w-48">
      {" "}
      <a
        target="_blank"
        href="https://www.capterra.com/reviews/183189/Cargo-Planner?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
      >
        {" "}
        <img
          border="0"
          src="https://assets.capterra.com/badge/a52dfee956302b196689b1d56ecbd187.svg?v=2128241&p=183189"
        />{" "}
      </a>
    </div>
  )
}

export default Capterra

import React from "react"

import { CheckCircleIcon } from "@heroicons/react/outline"
import Capterra from "./capterra"
import { Link } from "gatsby"

const Why = ({}) => {
  return (
    <div className="py-24 bg-white shadow" id="who">
      <h2 className="text-center text-4xl text-black font-semibold leading-snug tracking-wider">
        Why Cargo-Planner?
      </h2>
      <div className="container mx-auto mt-12 flex flex-wrap justify-center">
        <div className="w-full md:w-1/2 pr-3">
          <div className="w-full flex  mb-8">
            <div className="w-1/5 text-center">
              <CheckCircleIcon className="h-12 w-12 mx-auto text-primary" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl mb-2">The best results</p>
              <p className="text-xl">
                If you want to invest in a load planning tool it is of highest
                importance you get the best result. Just pick the basic example
                of loading Standard, non stackable pallets in sea containers.
                Most solutions will tell you 9 pallets can be fitted in a 20ft
                DV - ours give you the optimal solution of 10. Can you afford
                not getting the best results?
              </p>
            </div>
          </div>
          <div className="w-full flex mb-8">
            <div className="w-1/5 text-center">
              <CheckCircleIcon className="h-12 w-12 mx-auto text-primary" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl mb-2">
                Multi-container optimization
              </p>
              <p className="text-xl">
                The software supports selection of multiple container types to
                make sure that you are getting the most optimized combination
                for your cargoes.
              </p>
            </div>
          </div>
          <div className="w-full flex mb-8">
            <div className="w-1/5 text-center">
              <CheckCircleIcon className="h-12 w-12 mx-auto text-primary" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl mb-2">Container builder</p>
              <p className="text-xl">
                In Cargo-Planner each container and trailer type can be changed
                dynamically - which means you can create your own custom
                containers with custom measurements, axle setups and wall
                configurations.
              </p>
            </div>
          </div>
          <div className="w-full flex  mb-8">
            <div className="w-1/5 text-center">
              <CheckCircleIcon className="h-12 w-12 mx-auto text-primary" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl mb-2">Out of Gauge cargoes</p>
              <p className="text-xl">
                Cargo-Planner can load over sized cargoes in flat racks, open
                tops, flatbed trailers and PMC's - showing you how much overhang
                is used
              </p>
            </div>
          </div>
          <div className="w-full flex  mb-8">
            <div className="w-1/5 text-center">
              <CheckCircleIcon className="h-12 w-12 mx-auto text-primary" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl mb-2  ">
                Interactive integrations
              </p>
              <p className="text-xl">
                Using our SDK and API will allow you to easily embed an
                interactive 3D view in your application where the user can walk
                around the container and study the results on the fly
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-2xl mx-auto mt-12 flex flex-wrap justify-center">
        <span>
          Before making your decision - please{" "}
          <Link className="text-primary hover:text-gray-300" to="/register/">
            give Cargo-Planner a try!
          </Link>
        </span>
      </div>
      <div className="container mx-auto mt-12 flex flex-wrap justify-center">
        <Capterra />
      </div>
    </div>
  )
}

export default Why

import React from "react"

import {
  AcademicCapIcon,
  AdjustmentsIcon,
  ShareIcon,
  DeviceTabletIcon,
} from "@heroicons/react/outline"
import { useStaticQuery, graphql, Link } from "gatsby" // to query for image data
import { GatsbyImage } from "gatsby-plugin-image"

const Features1 = ({}) => {
  const data = useStaticQuery(graphql`
    {
      file(
        relativePath: { eq: "screens/workspace-view-with-mixed-trailers.png" }
      ) {
        base
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <div className="py-24 container px-3 mx-auto flex flex-wrap items-center">
      <div className="w-full lg:w-1/2 py-4 flex justify-center lg:justify-start">
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          alt={data.file.base.replace(/-/g, " ").split(".")[0]}
          className="shadow-xl"
        />
        {/* <StaticImage src="../images/screens/workspace-view-with-mixed-trailers.png" /> */}
      </div>

      <div className="w-full lg:w-1/2 py-4">
        <div className="w-full flex items-center mb-8">
          <div className="w-1/5 lg:text-center">
            <AcademicCapIcon className="h-12 w-12 mx-auto text-primary" />
          </div>
          <div className="w-4/5">
            <p className="font-bold text-xl">Easy onboarding, fast adoption</p>
            <p className="text-xl">
              Cargo-Planner is extremely easy to use, and usually doesn't
              require any training!
            </p>
          </div>
        </div>
        <div className="w-full flex items-center mb-8">
          <div className="w-1/5 lg:text-center">
            <AdjustmentsIcon className="h-12 w-12 mx-auto text-primary" />
          </div>
          <div className="w-4/5">
            <p className="font-bold text-xl">Easily modify the loadplans</p>
            <p className="text-xl">
              Convert containers or move around cargoes with your mouse
            </p>
          </div>
        </div>
        <div className="w-full flex items-center mb-8">
          <div className="w-1/5 lg:text-center">
            <ShareIcon className="h-12 w-12 mx-auto text-primary" />
          </div>
          <div className="w-4/5">
            <p className="font-bold text-xl">Share your work</p>
            <p className="text-xl">
              Share your load plans with your clients by URL, PDF or Excel - and
              give a professional impression
            </p>
          </div>
        </div>
        <div className="w-full flex items-center mb-8">
          <div className="w-1/5 lg:text-center">
            <DeviceTabletIcon className="h-12 w-12 mx-auto text-primary" />
          </div>
          <div className="w-4/5">
            <p className="font-bold text-xl">Use anywhere</p>
            <p className="text-xl">
              Access your load plans in your phone or tablet
            </p>
          </div>
        </div>
      </div>

      {/* <div className="w-full lg:w-1/2 py-4">
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          alt={data.file.base.replace(/-/g, " ").split(".")[0]}
          className="shadow-xl"
        />
        <StaticImage src="../images/screens/workspace-view-with-mixed-trailers.png" /> 
      </div>
      <div className="w-full md:w-1/2 pr-3">
        
      </div> */}

      <div className="w-full lg:w-1/2 py-4 lg:pt-48 pr-24">
        <h2 className="my-4 text-4xl font-semibold leading-snug tracking-wider">
          It starts with the packing manifest
        </h2>
        <p className="leading-normal text-xl  leading-normal">
          With our packlist reader, we are able to parse most Excel lists - no
          requirement on layout. Easily add cargo specific settings like
          stackability, allowed rotations, shipment groupings etc.
        </p>
        <br />
        <p className="leading-normal text-xl leading-normal">
          Then, select what kind of equipment you have available. Use{" "}
          <Link
            to="/equipment-library/"
            className="hover:underline text-blue-900"
          >
            our library
          </Link>{" "}
          with standard units, or create a container yourself.
          <b> Now, Cargo-Planner will choose the best combination!</b>
        </p>
      </div>
      <div className="w-full lg:w-1/2 py-4 lg:pt-48  ">
        <iframe
          title="Create a load plan in seconds!"
          className="object-contain pr-4  h-96 w-full  "
          src="https://www.youtube.com/embed/_l-HLFCnOYg"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default Features1

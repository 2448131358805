import React from "react"
import { CurrencyDollarIcon, ThumbUpIcon } from "@heroicons/react/outline"

const CaseStudy = ({}) => {
  return (
    <div className="py-24 bg-primary shadow">
      <div className="container mx-auto flex flex-wrap">
        <div className="w-full md:w-1/2 px-3">
          <h2 className="mb-4 text-4xl font-semibold text-white">Case study</h2>
          <p className="text-xl text-white">
            One of the <strong>top 5 largest forwarders in the world </strong>
            conducted a study in the spring of 2020 to evaluate the effects of
            implementing our tool for air container load planning. The results?
          </p>
        </div>
        <div className="w-full md:w-1/2 px-3 py-3 text-white">
          <div className="w-full flex items-center mb-5">
            <div className="w-1/5 text-center">
              <CurrencyDollarIcon className="h-12 w-12 mx-auto" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl">
                Load factor increased with 2.5%
              </p>
              <p className="text-xl">Higher capacity utilization!</p>
            </div>
          </div>
          <div className="w-full flex items-center mb-5">
            <div className="w-1/5 text-center">
              <ThumbUpIcon className="h-12 w-12 mx-auto" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl">
                Flown as booked percentage increased by 3%
              </p>
              <p className="text-xl">Higher customer satisfaction!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseStudy

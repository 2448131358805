import React from "react"
import { Link } from "gatsby"

import { CheckCircleIcon } from "@heroicons/react/outline"

const Modules = ({}) => {
  return (
    <div className="py-24 bg-white shadow" id="who">
      <h2 className="text-center text-4xl text-black font-semibold leading-snug tracking-wider">
        For whom?
      </h2>
      <div className="container mx-auto mt-12 flex flex-wrap justify-center">
        <div className="w-full md:w-1/2 pr-3">
          <div className="w-full flex  mb-8">
            <div className="w-1/5 text-center">
              <CheckCircleIcon className="h-16 w-16 mx-auto text-primary" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl mb-2">Forwarders</p>
              <p className="text-xl">
                As a forwarder you often get request for quotations and
                inquiries from customers. Use Cargo-Planner to give impressive
                load plans in no time - at the same time as the load plan is
                optimized hence increasing the chance of landing that project
              </p>
            </div>
          </div>
          <div className="w-full flex  mb-8">
            <div className="w-1/5 text-center">
              <CheckCircleIcon className="h-16 w-16 mx-auto text-primary" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl mb-2">Warehouses</p>
              <p className="text-xl">
                Now you can stop the time consuming trial and error process as
                well as making mock loads on the floor
              </p>
            </div>
          </div>
          <div className="w-full flex  mb-8">
            <div className="w-1/5 text-center">
              <CheckCircleIcon className="h-16 w-16 mx-auto text-primary" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl mb-2">Manufacturers</p>
              <p className="text-xl">
                Optimize your loading process and get structured load plans for
                your products. Start with load planning earlier on in your
                processes to increase efficiency. Give access to your
                distributors and customers and let them pick and choose which
                cargoes they want shipped for week 48
              </p>
            </div>
          </div>
          <div className="w-full flex mb-8">
            <div className="w-1/5 text-center">
              <CheckCircleIcon className="h-16 w-16 mx-auto text-primary" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl mb-2">Air Cargo Handlers</p>
              <p className="text-xl">
                Quickly see which setup of ULDs and pallets is the most optimal
                - utilize the contours and check how much to raise the floor for
                that winged PMC to utilize the load to the max. Or check which
                aircraft is most suitable for that charter
              </p>
            </div>
          </div>
          <div className="w-full flex mb-8">
            <div className="w-1/5 text-center">
              <CheckCircleIcon className="h-16 w-16 mx-auto text-primary" />
            </div>
            <div className="w-4/5">
              <p className="font-bold text-xl mb-2">System Integrators</p>
              <p className="text-xl">
                Does your client need a cargo loading feature in their new WMS /
                EMS? Using our Rest API makes it really easy. Read more about
                integrations{" "}
                <Link className="text-primary" to="/integration/">
                  here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modules

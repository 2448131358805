import React from "react"

import Testimonial from "./testimonial"
import { useStaticQuery, graphql } from "gatsby" // to query for image data

const T = props => {
  return (
    <Testimonial
      by="Steven Blunden, Business Change Manager, IAG Cargo"
      text="We have been using Cargo-Planner in our busy London hub for the past
      two years. Our focus has been on improving the efficiency of ULD build
      planning and the tool produces great results through a
      very user friendly interface."
      {...props}
    ></Testimonial>
  )
}

export default T

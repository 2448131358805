import React from "react"
import { graphql } from "gatsby" // to query for image data

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import CaseStudy from "../components/caseStudy"
import Features1 from "../components/why_and_video"
import Benefits from "../components/benefits"
import Modules from "../components/modules"
import SignupBanner from "../components/signupBanner"
import Why from "../components/why"
import ForWho from "../components/for_who"
import IAGTestimonial from "../components/testimonials/steven_iag"
import BertlingTestimonial from "../components/testimonials/hauke_bertling"
import GeodisTestiomnial from "../components/testimonials/alexander_geodis"
import CalendarView from "../components/calendar-view"
import IpadPlaceholder from "../components/responsive"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Container calculator" />
    {/* <div className="bg-white shadow"> */}
    <Hero
      video="/animated-boxes-lowres.mp4"
      image={data.heroImage}
      title="Ship Less Air"
      subtitle="Load planning in seconds"
      body="Save time, money and the environment by optimizing your containers with Cargo-Planner. Trusted by the largest forwarders, manufacturers and
        airlines."
      customers={[
        data.aircanada,
        data.kn,
        data.dsv,
        data.schneider,
        data.cmacgm,
        data.dhl,
      ]}
    />
    <hr />
    {/* </div> */}

    <Modules></Modules>
    <IAGTestimonial></IAGTestimonial>

    <CaseStudy />

    <Why />
    <CalendarView />

    <Features1 />
    <GeodisTestiomnial />

    <ForWho />
    <Benefits />
    <IpadPlaceholder />
    <BertlingTestimonial />
    <SignupBanner></SignupBanner>
  </Layout>
)

export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "40ft-hc-with-mixed-cargoes.png" }) {
      base
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    aircanada: file(relativePath: { eq: "customers/air-canada.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    dhl: file(relativePath: { eq: "customers/dhl.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    schneider: file(relativePath: { eq: "customers/schneider.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    dsv: file(relativePath: { eq: "customers/dsv.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    kn: file(relativePath: { eq: "customers/kn.jpg" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    cmacgm: file(relativePath: { eq: "customers/cmacgm.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
  }
`
export default IndexPage

import React from "react"

import Testimonial from "./testimonial"
import { useStaticQuery, graphql } from "gatsby" // to query for image data

const T = props => {
  return (
    <Testimonial
      by="Alexander Olsen, Manager, Industrial Projects, Freight Forwarding,
      GEODIS Sweden AB"
      text="Cargo-Planner is the ultimate tool for us and our clients in
      optimizing space utilization be it by road, sea, air or breakbulk -
      a true win-win solution!"
      {...props}
    ></Testimonial>
  )
}

export default T
